<template>
    <footer class="bg-primary">
        <div class="pt-6 pb-3 top-footer-cont border-bottom">
            <div class="container">
                <div class="row">
                    <div v-if="details.corporate_office" class="col-12 col-md-6 col-lg-4 mb-4">
                        <div class="fl-x fl-j-c pl-4 pl-md-0">
                            <div class="fl-y fl-a-s">
                                <h2 class="text-head fs-lg-2">Registered Office Address</h2>
                                <div>
                                    <div class="c-address font-inter-medium mb-1"
                                         v-if="details.corporate_office.office_name || details.corporate_office.address">

                                        <span v-if="details.corporate_office.office_name">
                                            {{ details.corporate_office.office_name }}
                                        </span> <br>
                                        <span v-if="details.corporate_office.address"
                                              v-html="details.corporate_office.address"/>
                                    </div>
                                    <p class="mb-1" v-if="details.corporate_office.contact_number">
                                        Phone: {{ details.corporate_office.contact_number }} <br>
                                        <!--                                        Toll Free: {{ details.corporate_office.toll_free_no }}-->
                                    </p>
                                    <p class="mb-1" v-if="details.corporate_office.email_id">
                                        <!--                                        Fax: {{ details.corporate_office.fax }} <br>-->
                                        Email : {{ details.corporate_office.email_id }}
                                    </p>
                                    <p class="mb-1" v-if="details.corporate_office.cin">
                                        CIN:{{ details.corporate_office.cin }} </p>
                                    <p v-if="details.corporate_office.gst_in">GST IN:{{
                                            details.corporate_office.gst_in
                                        }}</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div v-else class="col-12 col-md-6 col-lg-2 d-sm-none mb-4"/>

                    <div class="col-12 col-md-6 col-lg-4 d-sm-none mb-4">
                        <div class="fl-y lg-fl-a-c w-100">
                            <h2 class="text-head fs-lg-2">Company</h2>
                            <ul class="list-unstyled mb-3 ul-footer">
                                <li class="mb-2">
                                    <router-link to="/about/" class="d-block mb-2">About Us</router-link>
                                </li>
                                <li class="mb-2">
                                    <router-link :to="auctionLink" class="d-block mb-2">Auctions</router-link>
                                </li>
                                <!--                                <li class="mb-2">-->
                                <!--                                    <router-link to="/about/" class="d-block mb-2">Banks</router-link>-->
                                <!--                                </li>-->
                                <li class="mb-2">
                                    <router-link to="/contact/" class="d-block">Contact Us</router-link>

                                </li>
                                <!--                                <li class="mb-2">-->
                                <!--                                    <router-link to="/contact/" class="d-block">Get Help</router-link>-->
                                <!--                                </li>-->
                                <li class="mb-2">
                                    <router-link to="/faq/" class="d-block">FAQ</router-link>

                                </li>
                            </ul>
                        </div>
                    </div>

                    <!--                    <div class="col-12 col-md-6 col-lg-3 d-sm-none mb-4">-->
                    <!--                        <div class="fl-y fl-a-s">-->
                    <!--                            <h3 class="text-head fs-lg-2">Winning</h3>-->
                    <!--                            <ul class="list-unstyled mb-3 ul-footer">-->
                    <!--                                <li class="mb-2">-->
                    <!--                                    <router-link to="/" class="d-block">Auction Calender</router-link>-->
                    <!--                                </li>-->
                    <!--                                <li class="mb-2">-->
                    <!--                                    <router-link to="/" class="d-block">Auction House Directory</router-link>-->
                    <!--                                </li>-->
                    <!--                                <li class="mb-2">-->
                    <!--                                    <router-link to="/" class="d-block">Auction Price-->
                    <!--                                        Results</router-link>-->
                    <!--                                </li>-->
                    <!--                                <li class="mb-2">-->
                    <!--                                    <router-link to="/" class="d-block">Auctions Near Me</router-link>-->
                    <!--                                </li>-->
                    <!--                                <li class="mb-2">-->
                    <!--                                    <router-link to="/" class="d-block">How Auctions Work</router-link>-->
                    <!--                                </li>-->
                    <!--                                <li class="mb-2">-->
                    <!--                                    <router-link to="/" class="d-block">More Information</router-link>-->
                    <!--                                </li>-->
                    <!--                            </ul>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <div class="col-12 col-md-6 col-lg-4 mb-3 d-sm-none mb-lg-0">
                        <div class="fl-y lg-fl-a-c w-100">
                            <div>
                                <h3 class="text-head fs-lg-2 text-center mb-3">e Auction</h3>
                                <ul class="list-unstyled ul-footer">
                                    <li>
                                        <router-link to="/privacy-and-policy/"
                                                     class="text-decoration-none pos-r">
                                            Privacy Policy
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/terms-and-conditions/"
                                                     class="text-decoration-none pos-r">
                                            Terms & Conditions
                                        </router-link>
                                    </li>
                                    <!--                                <li class="mb-2">-->
                                    <!--                                    <router-link to="/">Legal Information</router-link>-->
                                    <!--                                </li>-->
                                    <!--                                <li class="mb-2">-->
                                    <!--                                    <router-link to="/">Auction House Directory</router-link>-->
                                    <!--                                </li>-->
                                    <!--                                <li class="mb-2">-->
                                    <!--                                    <router-link to="/">Auction Price Results</router-link>-->
                                    <!--                                </li>-->
                                    <!--                                <li class="mb-2">-->
                                    <!--                                    <router-link to="/">Auction Near Me</router-link>-->
                                    <!--                                </li>-->
                                    <!--                                <li class="mb-2">-->
                                    <!--                                    <router-link to="">How Auctions Work</router-link>-->
                                    <!--                                </li>-->
                                    <!--                                <li class="mb-2">-->
                                    <!--                                    <router-link to="">More Information</router-link>-->
                                    <!--                                </li>-->
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 mb-4 d-lg-none d-md-none">
                        <div class="fl-y fl-a-s">

                            <base-accordion heading="Company">
                                <template #default>
                                    <div class="fl-x fl-j-s ml-5">
                                        <ul class="list-unstyled mb-3 ul-footer">
                                            <li class="mb-2">
                                                <router-link to="/about/">About Us</router-link>

                                            </li>
                                            <li class="mb-2">
                                                <router-link :to="auctionLink" class="d-block mb-2">Auctions
                                                </router-link>
                                            </li>
                                            <!--                                            <li class="mb-2">-->
                                            <!--                                                <router-link to="/" class="d-block mb-2">Banks</router-link>-->
                                            <!--                                            </li>-->
                                            <li class="mb-2">
                                                <router-link to="/contact/" class="d-block">Contact Us</router-link>

                                            </li>
                                            <!--                                            <li class="mb-2">-->
                                            <!--                                                <router-link to="/contact/" class="d-block">Get Help</router-link>-->

                                            <!--                                            </li>-->
                                            <li class="mb-2">
                                                <router-link to="/faq/" class="d-block">FAQ</router-link>

                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </base-accordion>
                        </div>
                    </div>

                    <!--                    <div class="col-12 col-md-6 col-lg-3 mb-4 d-lg-none d-md-none d-none">-->
                    <!--                        <div class="fl-y fl-a-c">-->
                    <!--                            <base-accordion heading="Winning">-->
                    <!--                                <template #default>-->
                    <!--                                    <div class="fl-y fl-a-s ml-5">-->
                    <!--                                        <ul class="list-unstyled mb-3 ul-footer">-->
                    <!--                                            <li class="mb-2">-->
                    <!--                                                <router-link to="/" class="d-block">Auction Calender</router-link>-->
                    <!--                                            </li>-->
                    <!--                                            <li class="mb-2">-->
                    <!--                                                <router-link to="/" class="d-block">Auction House-->
                    <!--                                                    Directory</router-link>-->
                    <!--                                            </li>-->
                    <!--                                            <li class="mb-2">-->
                    <!--                                                <router-link to="/" class="d-block">Auction-->
                    <!--                                                    Price-->
                    <!--                                                    Results</router-link>-->
                    <!--                                            </li>-->
                    <!--                                            <li class="mb-2">-->
                    <!--                                                <router-link to="/" class="d-block">Auctions Near-->
                    <!--                                                    Me</router-link>-->
                    <!--                                            </li>-->
                    <!--                                            <li class="mb-2">-->
                    <!--                                                <router-link to="/" class="d-block">How Auctions-->
                    <!--                                                    Work</router-link>-->
                    <!--                                            </li>-->
                    <!--                                            <li class="mb-2">-->
                    <!--                                                <router-link to="/" class="d-block">More-->
                    <!--                                                    Information</router-link>-->
                    <!--                                            </li>-->
                    <!--                                            &lt;!&ndash;                            <li class="mb-2">&ndash;&gt;-->
                    <!--                                            &lt;!&ndash;                                <a href="/our-history/#historyBanner" class="d-block">More About our History</a>&ndash;&gt;-->
                    <!--                                            &lt;!&ndash;                            </li>&ndash;&gt;-->
                    <!--                                        </ul>-->
                    <!--                                    </div>-->
                    <!--                                </template>-->
                    <!--                            </base-accordion>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <div class="col-12 col-md-6 col-lg-4 mb-3 mb-lg-0 d-lg-none d-md-none">
                        <base-accordion heading="e Auction">
                            <template #default>
                                <div class="fl-y fl-a-s ml-5 pb-3">
                                    <ul class="list-unstyled ul-footer">
                                        <li>
                                            <router-link to="/privacy-and-policy/"
                                                         class="text-decoration-none pos-r">
                                                Privacy Policy
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/terms-and-conditions/"
                                                         class="text-decoration-none pos-r">
                                                Terms & Conditions
                                            </router-link>
                                        </li>
                                        <!--                                        <li class="mb-2">-->
                                        <!--                                            <router-link to="/">Legal Information</router-link>-->
                                        <!--                                        </li>-->
                                        <!--                                        <li class="mb-2">-->
                                        <!--                                            <router-link to="/">Auction House Directory</router-link>-->
                                        <!--                                        </li>-->
                                        <!--                                        <li class="mb-2">-->
                                        <!--                                            <router-link to="/">Auction Price Results</router-link>-->
                                        <!--                                        </li>-->
                                        <!--                                        <li class="mb-2">-->
                                        <!--                                            <router-link to="/">Auction Near Me</router-link>-->
                                        <!--                                        </li>-->
                                        <!--                                        <li class="mb-2">-->
                                        <!--                                            <router-link to="/">How Auctions Work</router-link>-->
                                        <!--                                        </li>-->
                                        <!--                                        <li class="mb-2">-->
                                        <!--                                            <router-link to="/">More Information</router-link>-->
                                        <!--                                        </li>-->
                                    </ul>
                                </div>
                            </template>
                        </base-accordion>

                    </div>

                    <div class="col-12" v-if="details.social_media.length!==0">
                        <div class="fl-x fl-j-c">
                            <div class="social-media-icons bg-primary">
                                <a v-for="(i, index) in details.social_media" :key="index" :href="i.website_link">
                                    <span v-if="i.site_map_type!=='Call us'"
                                          class="social-icon border-r-1 px-md-4 pr-lg-5 px-2">
                                        <img v-if="i.site_map_type==='Facebook'"
                                             src="../assets/web/social-media-icons/fb.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Instagram'"
                                             src="../assets/web/social-media-icons/insta.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='LinkedIn'"
                                             src="../assets/web/social-media-icons/linkedin.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Pinterest'"
                                             src="../assets/web/social-media-icons/pinterest.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='QQ'"
                                             src="../assets/web/social-media-icons/qq.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Quora'"
                                             src="../assets/web/social-media-icons/quora.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Reddit'"
                                             src="../assets/web/social-media-icons/reddit.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Sina Weibo'"
                                             src="../assets/web/social-media-icons/Sina_Weibo.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Skype'"
                                             src="../assets/web/social-media-icons/skype.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='SnapChat'"
                                             src="../assets/web/social-media-icons/Snapchat-.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Microsoft Teams'"
                                             src="../assets/web/social-media-icons/teams.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Telegram'"
                                             src="../assets/web/social-media-icons/telegram.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Twitter'"
                                             src="../assets/web/social-media-icons/twitter.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Youtube'"
                                             src="../assets/web/social-media-icons/utube.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='WeChat'"
                                             src="../assets/web/social-media-icons/Wechat.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='WhatsApp'"
                                             src="../assets/web/social-media-icons/whatsapp.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Whatsapp group'"
                                             src="../assets/web/social-media-icons/whatsapp-group.png" alt=""
                                             class="img-fluid"/>
                                        <img v-if="i.site_map_type==='Google Plus'"
                                             src="../assets/web/social-media-icons/google-plus.png" alt=""
                                             class="img-fluid"/>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-primary credit-section py-4 font-inter-extra-light fs--1">
            <div class="container">
                <div class="row">
                    <div class="col bottom-footer">
                        <p class="mb-lg-0 text-center w-100">&copy; {{ new Date().getFullYear() }} Bank e Auctions
                            India.com
                            . All Rights Reserved.
                            <!--                            <span>-->
                            <!--                                <router-link to="/" class="text-decoration-none pos-r">-->
                            <!--                                    Cookie Policy-->
                            <!--                                    <span class="underline-item-1"></span>-->
                            <!--                                </router-link>-->
                            <!--                            </span>-->
                            <span class="ml-1">
                                <router-link to="/terms-and-conditions/"
                                             class="text-decoration-none pos-r">
                                    Terms & Conditions
                                    <span class="underline-item-1"></span>
                                </router-link>
                            </span>
                            <!--                            <span class="ml-1">-->
                            <!--                                <a href="" class="text-decoration-none pos-r">-->
                            <!--                                    Intelluctual Property-->
                            <!--                                    <span class="underline-item-1"></span>-->
                            <!--                                </a>-->
                            <!--                            </span>-->
                            <span class="ml-1">
                                <router-link to="/privacy-and-policy/"
                                             class="text-decoration-none pos-r">
                                    Privacy Policy
                                    <span class="underline-item-1"></span>
                                </router-link>
                            </span>

                            <br>This site is protected by reCAPTCHA and the Google

                            <router-link to="/terms-and-conditions/" class="text-decoration-none pos-r">
                                Terms of Services <span class="underline-item-1"></span>
                            </router-link>
                            and

                            <router-link @click="$router.push('/privacy-and-policy/')" to="/privacy-and-policy/"
                                         class="text-decoration-none cursor-pointer pos-r">
                                Privacy Policy
                                <span class="underline-item-1"></span>
                            </router-link>
                            apply.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import BaseAccordion from '@components/ui/BaseAccordion';

export default {
    name: 'MainFooter',

    components: { BaseAccordion },

    props: {
        details: { type: Object, default: null },
        auctionLink: { type: String, default: '#' },

        currentRoute: {
            type: String,
            default: ''
        }
    },
    methods: {
        // routingPrevDefault (e) {
        //     this.$router.push(e.target.href);
        // }
    }
};
</script>

<style scoped lang="scss">
.underline-item {
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -7px;
    left: 0;
    background-color: rgba(228, 233, 242, 0.6);
}

.social-links {
    display: flex;
    //width: 80%;
    justify-content: flex-start;
    list-style: none;

    li {
        :not(:last-child) {
            transition: all ease-in-out 300ms;
            border: 2px solid #ddd;
            opacity: 0.6;
            border-radius: 0.5rem;
            height: 45px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 1.6rem;
                color: white;
            }

            &:hover {
                //transform: scale(1.03);

                i {
                    color: #9e9e9e;
                }
            }
        }
    }
}

.top-footer-cont {
    //background-color: #262626;
    //color: #888888;

    .text-head {
        //font-weight: bold !important;
        //font-size: 1.2rem;
        font-family: 'inter-semi-bold', sans-serif;
        margin-bottom: var(--spacer-4);
        color: #fafafa;
    }

    .text-details {
        font-weight: bold !important;
        font-family: 'Montserrat', sans-serif;
        color: #ffffff;
    }

    .list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            padding: 10px 0;
            border-bottom: 1px solid #333333;
            transition: 0.3s;

            &:hover {
                color: #cccccc;

                &:after {
                    color: #cccccc;
                }
            }

            &:after {
                font-family: "FontAwesome";
                content: "\f105";
                position: absolute;
                top: 10px;
                right: 0;
                font-size: 14px;
                color: #999999;
            }
        }
    }

}

ul {
    a {
        text-decoration: none;
    }
}

a:hover {
    color: #cccccc;
}

.bottom-footer-cont {
    background-color: #1b1b1b;
    color: #777777;

    .bottom-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .social-icons {
            li {
                display: inline-block;
                font-size: 1.4rem;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    @media (min-width: 992px) {
        .bottom-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.w-60 {
    width: 60% !important;
}

.text-white {
    &:hover {
        opacity: 0.6 !important;
    }
}

.ul-footer {
    li a {
        font-size: 0.9rem;
    }
}

.c-address {
    font-size: 0.9rem;
    font-style: normal !important;
    line-height: 2rem;
}

.border-bottom {
    border-bottom: 1px solid rgba(228, 233, 242, 0.2);
}

.footer-logo-container {
    img {
        min-height: 3.2rem;
    }
}

.social-icon {
    i {
        font-size: 1.7rem;
        color: rgba(255, 255, 255, 0.7);
    }
}

.h-5re {
    height: 5rem !important;
}

.social-media-icons {
    a img {
        height: 2rem;
        @media (min-width: 992px) {
            height: 2.2rem;
        }
    }
}
</style>
